@charset 'utf-8';
@import 'settings';
@import 'foundation';
@import 'motion-ui';
@include foundation-global-styles;
// @include foundation-grid;
// @include foundation-flex-grid;
//
@include foundation-xy-grid-classes;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
//@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-prototype-classes;
@include motion-ui-transitions;
@include motion-ui-animations;

//personalisation du _settings.scss
$foundation-palette: ( primary: #1779ba, secondary: #767676, success: #3adb76, warning: #ffae00, alert: #cc4b37, dekra: #007c3e, );

//Eviter le double menu lors du chargement
.no-js {
	@include breakpoint(small only) {
		.top-bar {
			display: none;
		}
	}
	@include breakpoint(medium) {
		.title-bar {
			display: none;
		}
	}
}

//block
//@import 'components/building-blocks/sticky-shrinknav';
@import 'components/building-blocks/responsive-timeline';
@import 'components/building-blocks/responsive-card-table';

//colors
$color_fun_green_approx: #007c3e;
$white: #fff;
$color_log_cabin_approx: #222;
$black: #000;
$color_wild_sand_approx: #f5f5f5;

//urls
$url_0: url(../img/visu-home2.jpg);

.header {
	text-align: center;
	background-color: $color_fun_green_approx;
	background-size: cover;
	position: relative;
	overflow: visible;
	padding-top: 2.5rem;
	padding-bottom: .5rem;
	.headline {
		color: $white;
		text-shadow: 0 0 8px $color_log_cabin_approx;
	}

}

header {
	.title-bar {
		background-color: $color_fun_green_approx;
	}
	a {
		color: $white;
		background-color: $color_fun_green_approx;
		&:focus {
			color: $color_fun_green_approx;
			background-color: $white;
			border-radius: 8px;
			text-shadow: 0 0 1px $color_log_cabin_approx;
		}
		&:hover {
			color: $color_fun_green_approx;
			background-color: $white;
			border-radius: 8px;
			text-shadow: 0 0 1px $color_log_cabin_approx;
		}
	}
	#widemenu {
		display: inline-block;
		text-align: center;
		width: 100%;
		background-color: $color_fun_green_approx;
		background-size: cover;
	.grid-container {
		background-color: $color_fun_green_approx;
		background-size: cover;
	}
	.title-bar {
		background-color: $color_fun_green_approx;
	}
	.top-bar {
		background-color: $color_fun_green_approx;
	}
	.menu {
		background-color: $color_fun_green_approx;
	}
	}
}

#accueil {
	padding: 50px 20px;
	text-align: center;
	span {
		text-decoration: underline;
	}
/* 	a {
		color: $color_fun_green_approx;
	} */
}

.hero {
	display: block;
	background: $url_0 no-repeat 50%;
	background-size: cover;
	width: 100%;
	color: $white;
	text-align: center;
	h1 {
		padding: .8em 0;
		text-shadow: 0 0 8px $color_log_cabin_approx;
	}
	h2 {
		padding: .8em 0;
		text-shadow: 0 0 8px $color_log_cabin_approx;
	}
	img {
		height: 185px;
	}
}

.contenu {
	background-color: $color_wild_sand_approx;
	p {
		padding: 20px;
		br {
			content: " ";
			display: block;
			margin-top: 10px;
			line-height: 22px;
		}
	}
	.tabs-title {
		>a {
			&:focus {
				color: $color_fun_green_approx;
			}
			&:hover {
				color: $color_fun_green_approx;
			}
		}
		>a[aria-selected=true] {
			color: $color_fun_green_approx;
		}
	}
	a {
		color: $color_fun_green_approx;
	}
	.media-object p {
		padding: 0 20px;
	}
	h3 a {
		color: $color_fun_green_approx;
	}
}

@media screen and(max-width:63.9375em) {
	.agenda {
		height: 100%;
		width: 100%;
	}
}

.agenda {
	height: 1010px;
	width: 100%;
}

footer {
	text-align: center;
	a {
		color: $color_fun_green_approx;
		&:hover {
			color: $white;
			background-color: $color_fun_green_approx;
		}
	}
}

table {
	text-align: center;
	thead th {
		text-align: center;
	}
}

.header-subnav ul.menu li.is-active a {
	border-radius: 8px;
	color: $black;
}

.header-subnav ul.menu li.is-active a {
	background-color: $white;
	color: $color_fun_green_approx;
}

.grid-container {
	background-color: $color_wild_sand_approx;
	//margin-top: 10px;
}

$hero-height: 48vh;
.hero-section {
	background: url("../img/visu-home2.jpg") 50% no-repeat;
	background-size: cover;
	height: $hero-height;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	.hero-section-text {
		color: $white;
		text-shadow: 1px 1px 2px $black;
	}
	img {
		height: 16vh;
		margin-top: 10px;
		margin-bottom: 20px;
	}
}

a {
	color: $color_fun_green_approx;
	&:hover {
		color: $white;
		background-color: $color_fun_green_approx;
	}
}

.card {
	margin-right: 5px;
	margin-left: 5px;
	ul.no-bullet {
		padding-left: 20px;
	}
}

.button {
	border-radius: 15px;
	//border: #000 2px solid;
	//text-shadow: #000 4px 1px 1px;
}

#code_by_dekra {
	width: 20%;
	min-width: 130px;
	height: 20%;
	min-height: 130px;
	padding-top: 5px;
	padding-bottom: 30px;
	//margin: auto;
}