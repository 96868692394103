$timeline-color: $white;
$timeline-transition: all 0.25s ease-in;
$timeline-item-card-bg: $white;
$timeline-item-font-color: $white;
$timeline-item-card: false;
$timeline-item-card-radius: $global-radius;


%clearfix {
  &::after,
  &::before {
    clear: both;
    content: '';
    display: block;
    width: 100%;
  }
}

// Timeline
.timeline {
  margin: 30px auto;
  padding: 0 10px;
  position: relative;
  transition: $timeline-transition;
  width: 100%;
  background-color: #007c3e;

  &::before {
    background: $timeline-color;
    content: '';
    height: 100%;
    left: 50%;
    position: absolute;
    top: 0;
    width: 3px;
  }

  &::after {
    clear: both;
    content: '';
    display: table;
    width: 100%;
  }

  a {
    color: $timeline-item-font-color;
    font-weight: bold;
    transition: $timeline-transition;

    &:hover {
      box-shadow: 0 1px 0px 0px $timeline-item-font-color;
      transition: $timeline-transition;
    }
  }

  .timeline-item {
    margin-bottom: 50px;
    position: relative;
    @extend %clearfix;

    .timeline-icon {
      background: $timeline-color;
      border-radius: 50%;
      height: 50px;
      left: 50%;
      margin-left: -23px;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 50px;

      img,
      svg {
        left: 14px;
        position: relative;
        top: 11px;
      }

      svg {
        top: 14px;
      }
    }

    .timeline-content {
      padding: 20px;
      text-align: right;
      transition: $timeline-transition;
      width: 45%;

      @if $timeline-item-card == true {
        background: $timeline-item-card-bg;
        border-radius: $timeline-item-card-radius;

        p {
          color: $black;
        }
      } @else {
        background: transparent;

        p {
          color: $timeline-item-font-color;
        }
      }

      p {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 0;
      }

      .timeline-content-date {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
      }

      .timeline-content-month {
        font-size: rem-calc(14);
        text-transform: uppercase;
        font-weight: 400;
      }

      &.right {
        float: right;
        text-align: left;
      }
    }
  }
}

@include breakpoint(small only) {
  .timeline {
    margin: 30px;
    padding: 0;
    width: 90%;

    &::before {
      left: 0;
    }

    .timeline-item {
      .timeline-content {
        float: right;
        text-align: left;
        width: 90%;

        &::before,
        &.right::before {
          border-left: 0;
          border-right: 7px solid $timeline-color;
          left: 10%;
          margin-left: -6px;
        }
      }

      .timeline-icon {
        left: 0;
      }
    }
  }
}
