$responsive-card-table-bottom-gap: 1.5rem;
$responsive-card-table-border: 1px solid $black;
$responsive-card-table-stack-point: 640px;
$responsive-card-table-label-bg: $light-gray;

.responsive-card-table {
  border-collapse: collapse;

  &.unstriped tbody {
    background-color: transparent;
  }

  th {
    background-color: $responsive-card-table-label-bg;
  }

  tr,
  th,
  td {
    border: $responsive-card-table-border;
  }

  @media screen and (max-width: $responsive-card-table-stack-point) {
    thead tr {
      position: absolute;
      top: -9999em;
      left: -9999em;
    }

    tr {
      border: $responsive-card-table-border;
      display: block;
    }

    tr + tr {      margin-top: $responsive-card-table-bottom-gap;
    }
    /* Get table cells to act like rows */

    td {
      border: none;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: $responsive-card-table-border;
      padding-left: 49%;
      position: relative;

      &:before {
        content: attr(data-label);
        display: inline-block;
        font-weight: bold;
        line-height: 1.5;
        margin-left: -100%;
        width: 100%;
        position: relative;
        z-index: 1;
      }

      &:after {
        content: '';
        position: absolute;
        background: $responsive-card-table-label-bg;
        width: 48%;
        height: 95%;
        left: 1px;
        top: 1px;
        z-index: 0;
      }
    }
  }
}
